import React, { useEffect, useState } from "react";
import { Section } from '../../../components/content'
import { Editor } from "@tinymce/tinymce-react";
import { IoIosArrowDown } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { uuidv4 } from '../../../helpers/helpers'
import { MdOutlineImage } from "react-icons/md";
import { notification } from "antd";
import { useAuth } from "../../../context/useAuth";
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config';
import { PERMISSIONS } from '../../../utils/permissions';
import jwt_decode from 'jwt-decode';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";

// DANIJEL'S KEY COMMENTED
const apiKey = "90yw8l16myyaolbcxwknry8ols5c4m4ty4p9jp348tbg1b12"; // "1567o1nwu4i84sd8t4269uvci7n84xlq1nm210qvss2sxmbn";
// STATUS OPTIONS
const statusOptions = [
  {name: 'Draft', value:'Draft'},
  {name: 'Published', value:'Published'},
]

const NewBlogPharmacyPage = () => {
  const { user } = useAuth();

  const location = useLocation();
  const navigate = useNavigate()
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.pharmacy))) {
          setGroceryPermissions(permissions.pharmacy);
        }
      }
    }
  }, [user, location]);

  const [blogCategories, setBlogCategories] = useState([])
  
  const [form, setForm] = useState({
    description: '',
    blogName: '',
    image: null,
    status: 'Published',
    link: '',
    author: '',
    category: {
      name: '',
      id: null,
    },
    tag: '',
    tags: [],
  })

  const [formError, setFormError] = useState({
    blogName: false,
  })

  const [openStatusDropdown, setOpenStatusDropdown] = useState(false)
  const [openCategoryDropdown, setOpenCategoryDropdown] = useState(false)
  const [creating, setCreating] = useState(false)

  const fetchBlogCategories = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getAllBlogCategories}`, {
        withCredentials:false,
        headers: {
          Authorization: `Bearer ${user.token}`,
          department: PERMISSIONS.pharmacy,
        }
      })

      if(response.status === 200){
        setBlogCategories(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const editorMenu = document.getElementsByClassName('tox-menubar')[0]
      const button = document.createElement('button')
      button.innerHTML = 'Upload WORD'
      button.classList.add('tox-mbtn')
      button.classList.add('tox-mbtn--select')
      button.onclick = () => {
        const inputUpload = document.getElementById('word_upload')
        if (!inputUpload) return

        inputUpload.click()
      }
      editorMenu.appendChild(button)
    },1000)
  },[])

  useEffect(() => {
    fetchBlogCategories()
  },[])

  const handleOpenDropdown = (field) => {
    switch(field){
      case 'status':
        setOpenStatusDropdown(prev => !prev)
        setOpenCategoryDropdown(false)
        break;
      case 'category':
        setOpenCategoryDropdown(prev => !prev)
        setOpenStatusDropdown(false)
        break;
      default:
        return
    }
  }

  const handleChangeField = (field, value) => {
    switch (field) {
      case 'blogName':
      case 'author':
      case 'link':
      case 'description':
      case 'status':
      case 'tag':
        setFormError(prev => {
          return {
            ...prev,
            [field]: !value
          }
        })
        setForm(prev => {
          return {
            ...prev,
            [field]: value
          }
        })
        break;
      case 'category':
        setFormError(prev => {
          return {
            ...prev,
            [field]: !value
          }
        })
        setForm(prev => {
          return {
            ...prev,
            [field]: {
              ...prev[field],
              name: value.name,
              id: value._id
            }
          }
        })
        break
      case 'image':
        const file = value.target.files[0]
        if(!file) return
        setForm(prev => {
          return {
            ...prev,
            image: file
          }
        })
        break;
      default:
        return
    }
  }

  const handleAddTag = (value, e) => {
    e.preventDefault()
    if(!value) return
    if(form.tags.includes(value)) {
      notification.info({
        message: 'Tag already exist.',
        placement:'bottomLeft'
      })
      return
    }

    setForm(prev => {
      return {
        ...prev,
        tag: '',
        tags: [
          ...prev.tags,
          value,
        ]
      }
    })
  }

  const handleRemoveTag = (tag) => {
    const {tags} = form
    const filteredArray = tags && tags.length > 0 ? tags.filter(i => i !== tag) : []
    setForm(prev => {
      return {
        ...prev,
        tags: filteredArray
      }
    })
  }

  const handleTriggerUpload = () => {
    if(form.image) return

    const input = document.getElementById('image')
    if(!input) return

    input.click()
  }

  const handleRemoveImage = () => {
    setForm(prev => {
      return {
        ...prev,
        image:null
      }
    })
  }

  const handleUploadWordToTinymce = async (e) => {
    const file = e.target.files[0]

    if(!file) return

    try {
      const arrayBuffer = await file.arrayBuffer()
      const result = await mammoth.convertToHtml({arrayBuffer})
      const html = result.value

      setForm(prev => {
        return {
          ...prev,
          description: html
        }
      })

    } catch (error) {
      console.error(error)
    }
  }

  const handleValidateToggle = (name) => {
    switch(name){
      case 'blogName':
        const checkBlogName = !form.blogName ? true : false
        setFormError(prev => {
          return {
            ...prev,
            blogName: checkBlogName
          }
        })
        break;
      default: 
        return
    }
  }

  const handleValidate = () => {
    let checkCase = false

    let checkBlogName = false
    
    if (!form.blogName){
      setFormError(prev => {
        return {
          ...prev,
          blogName: true,
        }
      })
      checkBlogName = false
    }else {
      setFormError(prev => {
        return {
          ...prev,
          blogName: false,
        }
      })
      checkBlogName = true
    }

    if(
      !checkBlogName
    ){ 
      setFormError(prev => {
        return {
          ...prev,
          blogName: true,
        }
      })

      checkCase = false
    }

    if(
      checkBlogName
    ) {
      checkCase = true
    }

    return checkCase
  }

  const handleCreateBlog = async () => {
    const validate = handleValidate()

    if(!validate) {
      return
    }
    setCreating(true)

    try {
      const {token} = user

      const formData = new FormData()
      formData.append('department', PERMISSIONS.pharmacy)
      formData.append('blogName', form.blogName)
      formData.append('description', form.description)
      formData.append('status', form.status)
      formData.append('link', form.link)
      formData.append('author', form.author)
      formData.append('category', form.category.id)
      formData.append('image', form.image)

      if (form.tags.length > 0) {
        form.tags.forEach(item => formData.append('tags', item));
      }

      const response = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.createBlogs}`, formData, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          department: PERMISSIONS.pharmacy,
        }
      })

      if (response.status === 201){
        setForm({
          description: '',
          blogName: '',
          image: null,
          status: 'Published',
          link: '',
          author: '',
          category: {
            name: '',
            id: null,
          },
          tag: '',
          tags: [],
        })
        
        notification.success({
          message: 'Successfully created blog.',
          placement: 'bottomLeft'
        })
        setCreating(false)

        setTimeout(() => {
          navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.blogs}`)
        }, 700);
      }
    } catch (error) {
      console.error(error)

      notification.error({
        message:'Error while creating a blog.',
        placement: 'bottomLeft'
      })

      setCreating(false)
    }
  }

  return (
    <>
      <h2 className="page-title">Create New Blog</h2>

      <Section className="section__wrapper section__blogs">
        <div className="container">
          <div className="left_side">
            <input
              id='word_upload'
              onChange={(e) => handleUploadWordToTinymce(e)}
              type="file"
              accept=".docx"
              style={{display:'none'}}
            />
            <Editor
              apiKey={apiKey}
              onEditorChange={(newValue, editor) =>
                handleChangeField('description',editor.getContent())
              }
              init={{
                height: '100%',
                menubar: true,
                plugins: "table",
              }}
              value={form.description}
            />
          </div>

          <div className="right_side">
            <div className="row">
              <span>Blog Name</span>

              <div>
                <input 
                  type='text' 
                  onChange={(e) => handleChangeField('blogName', e.target.value)} 
                  onBlur={() => handleValidateToggle('blogName')}
                  className="row_input" 
                  value={form.blogName}
                  name='blogName'
                />
              </div>

              <p 
                data-cy="modal-create-attribute-name-error"
                className={`error__onblur ${
                    formError.blogName ? "error" : ""
                  }`}
                >
                  * This field is required!
                </p>
            </div>

            <div className="row">
              <div>
                <input 
                  type='file' 
                  accept="image/*"
                  onChange={(e) => handleChangeField('image', e)} 
                  className="row_input" 
                  name='image'
                  id='image'
                  style={{
                    display:'none'
                  }}
                />

                <div onClick={() => handleTriggerUpload()} className={`image_upload_container ${!form.image ? 'cursor' : ''}`}>
                  {form.image ? (
                    <div className="image_preview">
                      <img src={URL.createObjectURL(form.image)}/>

                      <div onClick={() => handleRemoveImage()} className="remove_image">
                        <IoCloseOutline className="trash_can"/>
                      </div>
                    </div>
                  ) : (
                    <>
                      <MdOutlineImage className="image_icon"/>
                      <span className="image_name">Set Featurd Image</span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <span>Status</span>

              <div className="select_container">
                <div 
                  tabIndex={0} 
                  className="row_select" 
                  onBlur={() => {
                    setOpenStatusDropdown(false)
                  }}
                  onClick={() => handleOpenDropdown('status')}
                >
                  <span style={{textTransform:'capitalize'}}>{form.status}</span>

                  {openStatusDropdown && (
                    <div id='statusDropdown' className="blog_dropdown">
                      {statusOptions && statusOptions.length > 0 && statusOptions.map((option, index) => {
                        return (
                          <div onClick={() => handleChangeField('status', option.value)} key={index}>
                            <span>
                              {option.name}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>

                <IoIosArrowDown className='arrow_down'/>
              </div>
            </div>

            <div className="row">
              <span>Link</span>

              <div>
                <input 
                  type='text' 
                  onChange={(e) => handleChangeField('link', e.target.value)} 
                  className="row_input" 
                  value={form.link}
                  name='link'
                />
              </div>
            </div>

            <div className="row">
              <span>Author</span>

              <div>
                <input 
                  readOnly
                  type='text' 
                  onChange={(e) => handleChangeField('author', e.target.value)} 
                  className="row_input" 
                  value={form.author}
                  name='author'
                />
              </div>
            </div>

            <div className="row">
              <span>Add Tag</span>

              <form onSubmit={(e) => handleAddTag(form.tag, e)} className="tags">
                <div>
                  <input 
                    type='text' 
                    onChange={(e) => handleChangeField('tag', e.target.value)}
                    className="row_input" 
                    value={form.tag}
                    name='tag'
                  />
                </div>

                <div>
                  <button onClick={(e) => handleAddTag(form.tag, e)} disabled={!form.tag} className={`btn btn-primary blog_tag ${!form.tag ? 'disabled' :''}`}>Add</button>
                </div>
              </form>

              {form.tags && form.tags.length > 0 && (
                <div className="show_tags">
                  {form.tags.map((t,index) => {
                    return (
                      <button onClick={() => handleRemoveTag(t)} className="btn btn-primary tag_button" key={index}>
                        <span>{t}</span>
                        <div className="tag_btn_x">
                          <IoCloseOutline/>
                        </div>
                      </button>
                    )
                  })}
                </div>
              )}
            </div>

            <div className="row">
              <span>Category</span>

              <div className="select_container">
                <div 
                  tabIndex={0} 
                  className="row_select" 
                  onBlur={() => {
                    setOpenCategoryDropdown(false)
                  }}
                  onClick={() => handleOpenDropdown('category')}
                >
                  <span>{form.category.name || ''}</span>

                  {openCategoryDropdown && (
                    <div id='categoryDropdown' className="blog_dropdown">
                      <div onClick={() => handleChangeField('category', {name: '', _id: null})}>
                        <span style={{ color: '#d4d4d4'}}>
                          Select
                        </span>
                      </div>

                      {blogCategories && blogCategories.length > 0 && blogCategories.map((option, index) => {
                        return (
                          <div onClick={() => handleChangeField('category', option)} key={index}>
                            <span>
                              {option.name}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>

                <IoIosArrowDown className='arrow_down'/>
              </div>
            </div>

            <div className="row actions">
              <div>
                <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.blogs}`} className="btn btn-primary-outline">
                  Cancel
                </Link>
              </div>

              <div>
                <button disabled={creating} type="button" onClick={() => handleCreateBlog()} className="btn btn-primary">
                  {creating ? 'Creating...' : 'Create Blog'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default NewBlogPharmacyPage