import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Main, Section } from '../../../components/content'
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { useAuth } from '../../../context/useAuth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../../utils/permissions';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import SelectCustom from "../../../components/customSelect/CustomSelect";
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import Table from './Table';
import Loading from "../../../components/loading/Loading";
import Modal from '../../../components/modal/Modal';

const options = [5, 10, 20, 50, 100];

const BlogsPage = () => {
  const { user } = useAuth();

  const location = useLocation();
  const navigate = useNavigate()
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // PAGE
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [refetch, setRefetch] = useState(false);

  // READ
  const [readData, setReadData] = useState({});
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  // FILTERS
  const [disabledButton, setDisabledButton] = useState(false);

  // LOADER PROTECTION
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  const [toggleCreate, setToggleCreate] = useState(false);

  // CREATE - (unit)
  const createUnitFormRef = useRef(null);
  const [toggleCreateUnit, setToggleCreateUnit] = useState(false);
  const [createUnitForm, setCreateUnitForm] = useState({ name: "" }); // FORM data

  // DELETE
  const deleteFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: "" }); // ID for fetch

  // DELETE BULK
  const deleteBlukModalFormRef = useRef(null);
  const [toggleDeleteBulk, setToggleDeleteBulk] = useState(false);

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);

   const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  const handleOnSubmitSearch = (e) => {
    e.preventDefault()
    setSearch(searchForm.query)
    setRefetch(prev => !prev)
  }

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value})
  }

  const handleOnClearSearch = () => {
    setSearchForm({ query: ''})
    setSearch(null)
    setRefetch(prev => !prev)
  }

  // FETCH BLOGS
  const getReadData = useCallback(() => {
    const { token } = user;

    try {
      const response = axios
        .get(
          `${SERVER_URL}/${
            AXIOS_API_CALL.getBlogs
          }/${currentPage}/${limitPage}/${search || null}`,
          {
            withCredentials: false,
            headers: {
              Authorization: `Bearer ${token}`,
              department: PERMISSIONS.grocery,
            },
          }
        )
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch((err) => console.error(err))
        .finally();

      return response;
    } catch (err) {}
  }, [user, currentPage, limitPage, search]);

  useEffect(() => {
    let isMounted = true;
    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        if(res && res.data){
          setReadData(res?.data);
        }else {
          setReadData([]);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  const handleToggle = (props) => {
    const { name, data } = props;

    switch (name) {
      case "create":
        setToggleCreate(true);
        break;
      case "update":
        navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateBlog}/${data._id}`)
        break;
      case "delete":
        setToggleDelete(true);
        setDeleteFormId({ id: data._id });
        break;
      case "deleteBulk":
        setToggleDeleteBulk(true);
        break;
      default:
        console.warn("Default of: handleToggle function");
    }
  };

  async function handleOnSubmit({ event, form }) {
    event.preventDefault();

    const { name } = event.target;

    const { token } = user;

    switch (name) {
      case "delete":
        setDeleteBtnLoader(true);
          const deleteID = deleteFormId.id;

          try {
            await axios
              .delete(
                `${SERVER_URL}/${AXIOS_API_CALL.deleteBlog}/${deleteID}`,
                {
                  withCredentials: false,
                  headers: {
                    department: PERMISSIONS.grocery,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  setDeleteFormId({ id: "" });
                  form.onToggle(false);
                  form.formRef.current.reset();

                  notification.success({
                    message: res.data.message,
                    placement: "bottomLeft",
                  });
                  setTimeout(() => {
                    setDeleteBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                setDeleteBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        break;
      case "deleteBulk":
        setDeleteBtnLoader(true);
        const bulkPayload = {
          blogs: checkedRow,
        };
          await axios
            .post(
              `${SERVER_URL}/${AXIOS_API_CALL.deleteBulkBlogs}`,
              bulkPayload,
              {
                withCredentials: false,
                headers: {
                  Authorization: `Bearer ${token}`,
                  department: PERMISSIONS.grocery,
                },
              }
            )
            .then((res) => {
                setReadDataRefetch((prev) => !prev);
                setToggleDeleteBulk(false);
                setTimeout(() => {
                  setDeleteBtnLoader(false)
                },400)
            })
            .catch((err) => {
              console.error(err);
            });
        break;
      default:
        console.warn("Default of: handleOnSubmit function!");
    }
  }

  // PROPS

  // CREATE UNIT PROPS
  const createUnitProps = {
    active: toggleCreateUnit,
    onToggle: setToggleCreateUnit,
    formRef: createUnitFormRef,
    data: createUnitForm,
    onData: setCreateUnitForm,
  };

  // DELETE PROPS
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteFormRef,
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData?.data?.pagesCount,
    totalCount: readData?.data?.totalCount,
  };

  // TABLE PROPS
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows,
  };

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,

    createUnitProps: createUnitProps,
    deleteProps: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  // delete bulk
  const deleteBlukProps = {
    active: toggleDeleteBulk,
    onToggle: setToggleDeleteBulk,
    formRef: deleteBlukModalFormRef,
  };
  // ***
  const groceryData = readData && readData.data && readData.data.Data && readData.data.Data.length > 0 ? readData.data.Data.filter(item => item.department === PERMISSIONS.grocery) : []
 return (
  <>
    <h2 className="page-title">All Blogs</h2>

    <Section className="section__wrapper section__blogs">
      <header className="section__header">
          {
          !readDataLoading
           ? (
            <div className="filter">
              <span
                style={{
                  color: "#333",
                  fontWeight: "500",
                }}
              >
                Show:
              </span>
              <SelectCustom
                options={options}
                label={"Items"}
                limit={limitPage}
                setCurrentPage={setCurrentPage}
                setLimit={setLimitPage}
                setRefetch={setReadDataRefetch}
                loading={readDataLoading}
              />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: "155.5px", height: "50px" }} />
          )}

          {
          !readDataLoading
           ? (
            <div className="search">
              <form
                className="search-form"
                onSubmit={(e) => handleOnSubmitSearch(e)}
                data-cy="search-roles"
              >
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img
                      src="/assets/icons/search.svg"
                      alt="Search"
                      title="Search"
                    />
                  </span>
                  <input
                    value={searchForm.query || ""}
                    type="text"
                    data-cy="search-input-field"
                    className="input"
                    placeholder="Search"
                    onChange={(e) => handleOnChangeSearch(e.target.value)}
                  />
                  <span
                    className={`icon icon-close ${
                      !!searchForm.query && searchForm.query.length > 0
                        ? "isActive"
                        : ""
                    }`}
                    onClick={() => handleOnClearSearch()}
                  >
                    <img
                      src="/assets/icons/times.svg"
                      alt="Clear"
                      title="Clear"
                    />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder
              style={{
                width: "100%",
                maxWidth: "850px",
                height: "50px",
              }}
            />
          )}

          <div className="actions">
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.deleteBlogs
            ) && (
              <>
                {
                !readDataLoading
                 ? (
                  <button
                    type="button"
                    onClick={() =>
                      handleToggle({
                        name: "deleteBulk",
                        state: deleteBlukProps,
                      })
                    }
                    disabled={disabledButton ? false : true}
                    data-cy="delete-products-btn"
                    className="btn btn-primary-outline pl-4 pr-4"
                  >
                    <span className="text">Delete Blogs</span>
                  </button>
                ) : (
                  <LoadingPlaceholder
                    style={{ width: "189px", height: "50px" }}
                  />
                )}
              </>
            )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.createBlogs
            ) && (
              <>
                <div className="actions">
                  {
                  !readDataLoading
                   ? (
                    <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createBlog}`}
                      data-cy="create-attribute-btn"
                      className="btn btn-primary pl-4 pr-4"
                      style={{display:'flex',alignItems:'center'}}
                    >
                      <span style={{textAlign:'center'}} className="text">Create New Blog</span>
                    </Link>
                  ) : (
                    <LoadingPlaceholder
                      style={{
                        width: "191px",
                        height: "50px",
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </header>

        <Main className="section__content relative min-h-table-content">
          {
          readDataLoading
           ? (
            <Loading />
          ) : (
            <Table
              data={groceryData}
              {...tableProps}
              {...tableActionsProps}
              pagination={paginationProps}
            />
          )}
        </Main>

        {/* Delete Bulk Modal */}
        <Modal {...deleteBlukProps} className="sidebar__modal--center" title="">
          <form
            name="deleteBulk"
            onSubmit={(event) =>
              handleOnSubmit({
                event: event,
                name: "deleteBulk",
                data: checkedRow,
              })
            }
          >
            <h2 className="text-center mb-4">
              Are you sure you want to remove selected items ?
            </h2>

            <div className="form-group form-group-modal mb-4">
              <button
                data-cy="modal-delete-bulk-submit-btn"
                type="submit"
                className="btn btn-primary-link"
              >
                <span className="text">Yes</span>
              </button>

              <button
                data-cy="modal-delete-bulk-cancel-btn"
                type="button"
                className="btn btn-primary"
                onClick={() => setToggleDeleteBulk(false)}
              >
                <span className="text">No</span>
              </button>
            </div>
          </form>
        </Modal>

        {/* Delete */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.deleteBlogs
        ) && (
          <Modal {...deleteProps} className="sidebar__modal--center" title="">
            <form
              name="delete"
              ref={deleteFormRef}
              className="ant-form ant-form-horizontal"
              onSubmit={(event) =>
                handleOnSubmit({ event: event, form: deleteProps })
              }
            >
              <h2 className="text-center mb-4">
                Are you sure you want to delete the selected item ?
              </h2>

              <div className="form-group form-group-modal mb-4">
                {!deleteBtnLoader ? (
                  <button
                    data-cy="modal-delete-submit-btn"
                    type="submit"
                    className="btn btn-primary-link"
                  >
                    <span className="text">Yes</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Deleting...</span>
                  </button>
                )}

                <button
                  data-cy="modal-delete-cancel-btn"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setToggleDelete(false)}
                >
                  <span className="text">No</span>
                </button>
              </div>
            </form>
          </Modal>
        )}
    </Section>
  </>
 ) 
}

export default BlogsPage