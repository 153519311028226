import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../config/index';
import { useAuth } from '../context/useAuth';
import ProtectedRoute from './ProtectedRouter';
import jwt_decode from 'jwt-decode';

// Auth
import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

// Catch page
import Home from '../pages/home-page/Home';
import Profile from '../pages/profile-page/Profile';
import NotFoundError from '../pages/error-page/NotFoundError';

// Component pages - GROCERY
import RolesGrocery from '../pages/grocery/roles-page/Roles';
import EmployeesGrocery from '../pages/grocery/employees-page/Employees';
import CustomersGrocery from '../pages/grocery/customers-page/Customers';
import ActivityGrocery from '../pages/grocery/activity-page/Activity';
import CategoriesGrocery from '../pages/grocery/categories-page/Categories';
import AttributesGrocery from '../pages/grocery/attributes-page/Attributes';
import OrdersGrocery from '../pages/grocery/orders-page/Orders';
import OrderGroceryDetails from '../pages/grocery/order-details-page/OrderDetails';
import DeliveryGrocery from '../pages/grocery/delivery-page/Delivery';
import GalleryGrocery from '../pages/grocery/gallery-page/Gallery';
import ProductsGrocery from '../pages/grocery/products-page/Products';
import ProductGroceryCreate from '../pages/grocery/products-page/create-product/CreateProduct';
import ProductGroceryUpdate from '../pages/grocery/products-page/update-product/UpdateProduct';
import CouponsGrocery from '../pages/grocery/coupons-page/Coupons';
import GeoLocationGrocery from '../pages/grocery/geo-location-page/GeoLocation';
import CreateOrder from '../pages/grocery/orders-page/CreateOrder';
import AccountSettings from '../pages/grocery/orders-page/AccountSettings';
import ShippingAddress from '../pages/grocery/orders-page/ShippingAddress';
import Blogs from '../pages/grocery/blogs-page/Blogs';
import NewBlogPage from '../pages/grocery/blogs-page/NewBlog';
import EditBlogPage from '../pages/grocery/blogs-page/EditBlog';
import BlogCategoriesPage from '../pages/grocery/blog-categories/BlogCategories'

// Component pages - PHARMACY
import RolesPharmacy from '../pages/pharmacy/roles-page/Roles';
import CustomersPharmacy from '../pages/pharmacy/customers-page/Customers';
import EmployeesPharmacy from '../pages/pharmacy/employees-page/Employees';
import ActivityPharmacy from '../pages/pharmacy/activity-page/Activity';
import CategoriesPharmacy from '../pages/pharmacy/categories-page/Categories';
import AttributesPharmacy from '../pages/pharmacy/attributes-page/Attributes';
import OrdersPharmacy from '../pages/pharmacy/orders-page/Orders';
import OrderPharmacyDetails from '../pages/pharmacy/order-details-page/OrderDetails';
import DeliveryPharmacy from '../pages/pharmacy/delivery-page/Delivery';
import ProductsPharmacy from '../pages/pharmacy/products-page/Products';
import ProductPharmacyCreate from '../pages/pharmacy/products-page/create-product/CreateProduct';
import ProductPharmacyUpdate from '../pages/pharmacy/products-page/update-product/UpdateProduct';
import CouponsPharmacy from '../pages/pharmacy/coupons-page/Coupons';
import GeoLocationPharmacy from '../pages/pharmacy/geo-location-page/GeoLocation';
import GalleryPharmacy from '../pages/grocery/gallery-page/Gallery';
import AccountSettingsPharmacy from '../pages/pharmacy/orders-page/AccountSettings';
import ShippingAddressPharmacy from '../pages/pharmacy/orders-page/ShippingAddress';
import CreateOrderPharmacy from '../pages/pharmacy/orders-page/CreateOrder';
import BlogsPharmacy from '../pages/pharmacy/blogs-page/Blogs';
import NewBlogPharmacyPage from '../pages/pharmacy/blogs-page/NewBlog';
import EditBlogPharmacyPage from '../pages/pharmacy/blogs-page/EditBlog';
import BlogCategoriesPharmacyPage from '../pages/pharmacy/blog-categories/BlogCategories'

// AUTH VERIFICATION APP
import { getGroceryPermissions, getPharmacyPermissions } from '../middleware';
import { AXIOS_API_CALL } from '../utils/endpoint';
import { PERMISSIONS, USER } from '../utils/permissions';
import Loading from '../components/loading/Loading';
import EmployeeEmailVerification from '../pages/auth/EmployeeEmailVerification';

const Router = (props) => {
  const { pathname } = props;
  const { user, refetchToken } = useAuth();
  const location = useLocation();

  const getUserTokenStatus = async () => {
    const checkUser = window.sessionStorage.getItem(USER);

    if (!checkUser || checkUser.includes('null') || checkUser.includes('undefined')) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      return false;
    }

    const jsonToken = JSON.parse(checkUser);

    if (!jsonToken) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;

      return false;
    }

    try {
      const fetchToken = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.userCheckToken}`, null, { withCredentials: false, headers: { Authorization: `Bearer ${jsonToken.token}` } });

      if (fetchToken.status !== 200) {
        window.sessionStorage.setItem(USER, JSON.stringify(null));
        window.location.href = `/${PERMISSIONS.login}`;
      }
    } catch (err) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
    }
  };

  // TOKEN VALIDATION
  useEffect(() => {
    if (user && user.token) {
      getUserTokenStatus();
    }
  }, [user, location, refetchToken]);

  // TODO: testing verify route
  const getVerifyUserPermissonsToken = async () => {
    const checkUser = window.sessionStorage.getItem(USER);

    if (!checkUser || checkUser.includes('null') || checkUser.includes('undefined')) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      return false;
    }

    const jsonToken = JSON.parse(checkUser);

    if (!jsonToken) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;

      return false;
    }

    try {
      const fetchVerify = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.userCheckPermissions}`, { token: jsonToken.token }, { withCredentials: false, headers: { Authorization: `Bearer ${jsonToken.token}` } });

      if (fetchVerify.status === 200) {
        const message = fetchVerify.data.message;

        if (message === 'permissions_not_valid') {
          window.sessionStorage.setItem(USER, JSON.stringify(null));
          window.location.href = `/${PERMISSIONS.login}`;
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // VERIFY USER PERMISSIONS TOKEN
  useEffect(() => {
    if (user && user.token) {
      getVerifyUserPermissonsToken();
    }
  }, [user, location, refetchToken]);

  // CHECK USER SESSION STORAGE
  useEffect(() => {
    const checkUser = window.sessionStorage.getItem(USER);
    if (!checkUser) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      window.location.reload();
    }
  }, [location]);

  // CHANGE OFFFICAL COLOR
  useLayoutEffect(() => {
    if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
      document.documentElement.style.setProperty('--official-color', 'hsl(230, 76%, 59%)'); // 59%
    }
    if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
      document.documentElement.style.setProperty('--official-color', 'hsl(165, 150%, 35%)'); // 40%
    }

    if (location.pathname.includes(`/${PERMISSIONS.login}`)) {
      document.documentElement.style.setProperty('--official-color', 'hsl(230, 76%, 59%)'); // 59%
    }
  }, [location]);

  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [pharmacyPermissions, setPharmacyPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    if (user && user.token) {
      const token = user.token;
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) || location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.pharmacy))) {
          setPharmacyPermissions(permissions.pharmacy);
        }
      }
    }
  }, [user, location]);

  const getLocationPathname = useCallback(
    (path) => {
      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${path}`)) return true;
      return false;
    },
    [location]
  );

  return (
    <Routes>
      {/* public routes */}
      <Route index element={<Navigate to={`/${PERMISSIONS.login}`} replace />} />
      <Route path={`/${PERMISSIONS.login}`} element={<Login />} />
      <Route path={`/${PERMISSIONS.forgotPassword}`} element={<ForgotPassword />} />
      <Route path={`/${PERMISSIONS.passwordReset}/:id`} element={<ResetPassword />} />
      <Route path={`/${PERMISSIONS.employeeEmailVerification}/:token`} element={<EmployeeEmailVerification />} />

      {/* protected routes */}
      <Route element={<ProtectedRoute pathname={pathname} redirectPath={`/${PERMISSIONS.login}`} />}>
        <Route index path={`/${PERMISSIONS.dashboard}`} element={<Navigate to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`} replace />} />

        {/* GROCERY */}
        {getLocationPathname(PERMISSIONS.grocery) ? (
          <>
            <Route index path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`} element={<Home />} />
            <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`} element={<Profile />} />
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`} element={<RolesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCustomers) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}`} element={<CustomersGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`} element={<EmployeesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.activity}`} element={<ActivityGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.categories}`} element={<CategoriesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`} element={<AttributesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`} element={<OrdersGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}`} element={<CreateOrder/>} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}`} element={<AccountSettings/>} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}/${PERMISSIONS.shippingAddress}`} element={<ShippingAddress/>} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}/:id`} element={<OrderGroceryDetails />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readDelivery) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.delivery}`} element={<DeliveryGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`} element={<GalleryGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`} element={<ProductsGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createProduct}`} element={<ProductGroceryCreate />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateProduct}/:id`} element={<ProductGroceryUpdate />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.coupons) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.coupons}`} element={<CouponsGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readGeoLocations) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.geoLocations}`} element={<GeoLocationGrocery />} />}
            
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readBlogs) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`} element={<Blogs/>}/>}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createBlogs) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createBlog}`} element={<NewBlogPage/>}/>}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateBlogs) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateBlog}/:id`} element={<EditBlogPage/>}/>}

            {getGroceryPermissions(groceryPermissions, PERMISSIONS.read_blog_category) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blog_category}`} element={<BlogCategoriesPage/>}/>}
          </>
        ) : null}

        {/* PHARMACY */}
        {getLocationPathname(PERMISSIONS.pharmacy) ? (
          <>
            <Route index path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`} element={<Home />} />
            <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.profile}`} element={<Profile />} />
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readRoles) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.roles}`} element={<RolesPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.products}`} element={<ProductsPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readCustomers) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.customers}`} element={<CustomersPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readEmployees) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.employees}`} element={<EmployeesPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readActivity) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.activity}`} element={<ActivityPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readCategories) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.categories}`} element={<CategoriesPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readAttributes) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.attributes}`} element={<AttributesPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.orders}`} element={<OrdersPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.createOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}`} element={<CreateOrderPharmacy/>} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.createOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}`} element={<AccountSettingsPharmacy/>} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.createOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}/${PERMISSIONS.shippingAddress}`} element={<ShippingAddressPharmacy/>} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.orders}/:id`} element={<OrderPharmacyDetails />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readDelivery) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.delivery}`} element={<DeliveryPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readGallery) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.gallery}`} element={<GalleryPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.createProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createProduct}`} element={<ProductPharmacyCreate />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.updateProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.updateProduct}/:id`} element={<ProductPharmacyUpdate />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.coupons) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.coupons}`} element={<CouponsPharmacy />} />}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readGeoLocations) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.geoLocations}`} element={<GeoLocationPharmacy />} />}

            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.readBlogs) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.blogs}`} element={<BlogsPharmacy/>}/>}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.createBlogs) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createBlog}`} element={<NewBlogPharmacyPage/>}/>}
            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.updateBlogs) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.updateBlog}/:id`} element={<EditBlogPharmacyPage/>}/>}

            {getPharmacyPermissions(groceryPermissions, PERMISSIONS.read_blog_category) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.blog_category}`} element={<BlogCategoriesPharmacyPage/>}/>}
          </>
        ) : null}

        {/* catch all */}
        <Route path="*" element={<NotFoundError />} />
      </Route>
    </Routes>
  );
};

export default Router;
