import { PERMISSIONS } from './permissions';
import { getGroceryPermissions, getPharmacyPermissions } from '../middleware';

const groceryMenuItems = (props) => {
  const { groceryPermissions, handleLogout, onHide } = props;
  const grocery = [
    {
      _id: '',
      label: 'Store',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes) || getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories) || getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
      onClick: function (value) { },
      children: [
        {
          _id: '',
          label: 'Attributes',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Products',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/products.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-products',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
          onClick: function () {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: 'Categories',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.categories}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/categories.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-categories',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories),
          onClick: function () {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: 'Coupons',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.coupons}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/categories.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-categories',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCoupons),
          onClick: function () {
            onHide(false);
          },
          children: [],
        },
      ],
    },
    {
      _id: '',
      label: 'Orders',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/orders.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-orders',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Delivery',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.delivery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/delivery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-pharmacy-delivery',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readDelivery),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Geo Location',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.geoLocations}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/geolocation.png',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-orders',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readGeoLocations),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Gallery',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/gallery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Blogs',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/blog_icon.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readBlogs),
      onClick: function () {},
      children: [
        {
          _id: '',
          label: 'All Blogs',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-blogs',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readBlogs),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Categories',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blog_category}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-blog-categories',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.read_blog_category),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    {
      _id: '',
      label: 'Roles',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/roles.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Customers',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/customers.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-customers',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCustomers),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Employees',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-employees',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Activity Log',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.activity}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/activity.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-activity-log',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Profile',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/profile.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Logout',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return grocery;
};

const pharmacyMenuItems = (props) => {
  const { pharmacyPermissions, handleLogout, onHide } = props;

  const pharmacy = [
    {
      _id: '',
      label: 'Store',
      path: 'store',
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-pharmacy-store-dropdown',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readCategories) || getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readAttributes) || getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readProducts),
      onClick: function (value) { },
      children: [
        {
          _id: '',
          label: 'Attributes',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.attributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-pharmacy-attributes',
          visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Products',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.products}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/products.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-pharmacy-products',
          visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readProducts),
          onClick: function () {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: 'Categories',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.categories}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/categories.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-pharmacy-categories',
          visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readCategories),
          onClick: function () {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: 'Coupons',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.coupons}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/categories.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-categories',
          visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readCoupons),
          onClick: function () {
            onHide(false);
          },
          children: [],
        },
      ],
    },
    {
      _id: '',
      label: 'Orders',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.orders}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/orders.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-pharmacy-orders',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readOrders),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Delivery',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.delivery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/delivery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-delivery',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readDelivery),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Geo Location',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.geoLocations}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/geolocation.png',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-orders',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readGeoLocations),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Gallery',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.gallery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/gallery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readGallery),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Blogs',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.blogs}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/blog_icon.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readBlogs),
      onClick: function () {},
      children: [
        {
          _id: '',
          label: 'All Blogs',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.blogs}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-blogs',
          visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readBlogs),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Categories',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.blog_category}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-blog-categories',
          visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.read_blog_category),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    {
      _id: '',
      label: 'Roles',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.roles}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/roles.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-pharmacy-roles',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readRoles),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Customers',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.customers}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/customers.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-pharmacy-customers',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readCustomers),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Employees',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.employees}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-pharmacy-employees',
      visible: getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readEmployees),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Activity Log',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.activity}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/activity.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-pharmacy-activity-log',
      visible: true,
      // getPharmacyPermissions(
      //   groceryPermissions,
      //   PERMISSIONS.readActivity
      // ),
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Profile',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.profile}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/profile.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-pharmacy-profile',
      visible: true,
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Logout',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-pharmacy-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return pharmacy;
};

export { groceryMenuItems, pharmacyMenuItems };
